import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { borderRadius, skylineColor, stylisticFont } from './constants';

const solid = defineStyle((props) => ({
  background: skylineColor,
  color: 'white',
  borderRadius,
  _hover: {
    background: skylineColor,
  },
  fontSize: '16px',
  fontWeight: '600',
}));

const outline = defineStyle({
  borderColor: skylineColor,
  color: 'white',
  borderRadius,
  borderWidth: '2px',
  px: '25px',
});

const login = defineStyle({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '7px',
  width: '100%',
  height: '55px',
  mt: '25px',
  borderRadius,
  bgColor: skylineColor,
  fontWeight: '600',
  fontSize: '16px',
  fontFamily: stylisticFont,
});

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: stylisticFont,
  },
  defaultProps: {
    variant: 'solid',
  },
  variants: {
    login,
    solid,
    outline,
  },
});
