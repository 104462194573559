import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';

import { Sentry } from 'configureSentry';
import { StatsigGates } from 'StatsigGates';

let currentVersion: string | undefined;
let availableVersion: string | undefined;

async function getLatestVersion(): Promise<string | undefined> {
  try {
    const resp = await axios.get<string>(`/version.txt?${Date.now()}`);
    return resp.data.trim().toUpperCase();
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
}

void getLatestVersion().then((initial) => {
  currentVersion = initial;
  console.log(`Current Version: ${currentVersion}`);

  setInterval(() => {
    void getLatestVersion().then((v) => {
      availableVersion = v;
    });
  }, 1000 * 120);
});

export function useUpdateCheck(): void {
  const { pathname } = useLocation();
  const gate = useGate(StatsigGates.AUTO_UPDATE);

  useEffect(() => {
    if (!gate.value) return;

    if (availableVersion && currentVersion && (currentVersion !== availableVersion)) {
      const msg = `Update available [${currentVersion} -> ${availableVersion}]`;
      console.warn(msg);
      Sentry.captureMessage(msg);
      window.location.reload();
    } else {
      console.trace(`No update available ${availableVersion}`);
    }
  }, [gate.value, pathname]);
}
