import 'api/configureAxios';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useLogger } from 'react-use';

import ErrorFallback from 'components/error/ErrorFallback';
import PageTemplate from 'components/page-template/PageTemplate';
import AxiosProvider from 'contexts/axios/AxiosProvider';
import UserContextProvider from 'contexts/user/UserContextProvider';
import { useUpdateCheck } from 'hooks/useUpdateCheck';
import RouteIndex from 'RouteIndex';
import { newTheme } from 'themes/theme';

console.log('*************** App Started ***************');

const router = createBrowserRouter([
  { path: '*', element: <Root /> },
]);

function Root(): JSX.Element {
  useLogger('App');
  useUpdateCheck();

  return (
    <ChakraProvider theme={newTheme} toastOptions={{ defaultOptions: { position: 'top', variant: 'solid' } }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AxiosProvider>
          <UserContextProvider>
            <PageTemplate>
              <RouteIndex />
            </PageTemplate>
          </UserContextProvider>
        </AxiosProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

function App(): JSX.Element {
  return <RouterProvider router={router} />;
}

export default App;
