import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';

import ChangePassword from './ChangePassword';
import DicomServersList from './dicom/DicomServersList';
import EditClinic from './EditClinic';
import EditProfile from './EditProfile';
import PacsInfo from './PacsInfo';

function SettingsPage(): JSX.Element {
  return (
    <Tabs>
      <TabList>
        <Tab>My Profile</Tab>
        <Tab>My Clinic</Tab>
        <Tab>PACS Info</Tab>
        <Tab>Dicom Servers</Tab>
        <Tab>Change Password</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <EditProfile />
        </TabPanel>
        <TabPanel>
          <EditClinic />
        </TabPanel>
        <TabPanel>
          <PacsInfo />
        </TabPanel>
        <TabPanel>
          <DicomServersList />
        </TabPanel>
        <TabPanel>
          <ChangePassword />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default SettingsPage;
